<template>
  <div class="main-container">

    <div id="particles-js"></div>
    <div class="countdown-bg"></div>

    <div class="error-screen" style="color: #0E5E4E">
      <h1>404</h1>
      <h5>Desculpe!<br/>A Página solicitada não foi encontrada.</h5>
<!--      <a href="index.html" class="btn btn-primary">Go back to Dashboard</a>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnauthorizedIndex',

  mounted() {
  },

  methods: {
  }
}
</script>

<style>

</style>
